import * as Sentry from '@sentry/gatsby'
import { Integrations } from '@sentry/tracing'

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://632996bce4582a515be73d3158df94f7@sentry-new.euw2.devops-algbra.com/3',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  })
}
